<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div>

    <div class="row mb-1">
      <div class="col-md-4">
        <div class="form-group">
          <input v-model="filter.search" type="text" class="form-control" placeholder="Search...">
        </div>
      </div>
      <div class="col-md-4">
        <b-tabs>
          <b-tab v-for="item in [
            { key: 'all', label: 'All Subs' },
            { key: 'active', label: 'Active' },
            { key: 'billed', label: 'Billed' },
            { key: 'deactive', label: 'Deactive' },
          ]" :key="item.key" :title="item.label" @click="filterStatus(item.key)" />
        </b-tabs>
      </div>
      <!-- <div class="col-md-2"> -->
      <!-- <button
          class="btn btn-primary btn-block"
          :disabled="isLoadingExort"
          @click="exportData()"
        >
          <b-spinner
            v-if="isLoadingExort"
            label="Loading..."
            variant="light"
            small
            class="ml-1"
          />
          <span v-else>Export</span>
        </button> -->
      <!-- </div> -->
      <div class="col-md-2">
        <button v-b-modal.form-modal class="btn btn-primary btn-block h-100" @click="cleanUpForm()">
          Create
        </button>
      </div>
      <div class="col-md-2">
        <button v-b-modal.form-modal-manual-subscription class="btn btn-primary btn-block h-100" @click="cleanUpForm()">
          Add Manual Subscription
        </button>
      </div>
    </div>

    <b-card-code no-body>
      <div class="table-responsive-sm" style="overflow-x: auto">
        <table class="table b-table table-fixed">
          <thead>
            <tr>
              <th>Store Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Status</th>
              <th>Join Date</th>
              <th>User</th>
              <th>Branch</th>
              <th>Remaining Renewal Days</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr v-if="isLoading">
              <td colspan="5" class="text-center">
                <b-spinner label="Loading..." variant="primary" />
              </td>
            </tr>
            <tr v-for="item in result.data" v-if="!isLoading" :key="item">
              <td>{{ item.name || '-' }}</td>
              <td>{{ item.owner ? item.owner.email || '-' : '-' }}</td>
              <td>{{ item.owner ? item.owner.phone || '-' : '-' }}</td>
              <td>{{ item.renewal_status || '-' }}</td>
              <td>{{ item.register_date || '-' }}</td>
              <td>{{ item.user_count }}</td>
              <td>{{ item.branch_count }}</td>
              <td>{{ item.remaining_renewal_days || 0 }} days</td>
              <td class="text-right">
                <div class="btn-group" role="group" aria-label="Basic example">

                  <button class="btn btn-success btn-sm" style="margin-left: 2px;" data-toggle="tooltip"
                    data-placement="top" title="Detail" @click="detailItem(item)">
                    <feather-icon icon="EyeIcon" />
                  </button>

                  <button class="btn btn-info btn-sm" style="margin-left: 2px;" data-toggle="tooltip"
                    data-placement="top" title="Edit" @click="editItem(item)">
                    <feather-icon icon="EditIcon" />
                  </button>

                  <button class="btn btn-danger btn-sm" style="margin-left: 2px;" data-toggle="tooltip"
                    data-placement="top" title="Delete" @click="deleteItem(item.id)">
                    <feather-icon icon="Trash2Icon" />
                  </button>

                </div>
              </td>
            </tr>
            <tr v-if="result.total == 0 && !isLoading">
              <td colspan="5" class="text-center">
                Data is empty.
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row mt-1">
          <div class="col-6 pl-2">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col-6 pr-2">
            <pagination :data="result" :limit="4" align="right" @pagination-change-page="getData" />
          </div>
        </div>
      </div>
    </b-card-code>

    <!-- Detail Modal -->
    <b-modal id="detail-modal" no-close-on-backdrop size="xl" title="Detail">

      <table v-if="detail != null" class="table table-hover">
        <tr>
          <td style="width: 30%;">
            Name
          </td>
          <td>{{ detail.name }}</td>
        </tr>
        <tr>
          <td style="width: 30%;">
            Logo
          </td>
          <td>
            <img v-if="detail.logo_url" :src="detail.logo_url" alt="Logo" style="max-width: 100px;">
            <span v-else>-</span>
          </td>
        </tr>
        <tr>
          <td>Owner Name</td>
          <td>{{ detail.owner ? detail.owner.name || '-' : '-' }}</td>
        </tr>
        <tr>
          <td>Owner Email</td>
          <td>{{ detail.owner ? detail.owner.email || '-' : '-' }}</td>
        </tr>
        <tr>
          <td>Owner Phone</td>
          <td>{{ detail.owner ? detail.owner.phone || '-' : '-' }}</td>
        </tr>
        <tr>
          <td>Address</td>
          <td>{{ detail.address || '-' }}</td>
        </tr>
        <tr>
          <td>City</td>
          <td>{{ detail.city || '-' }}</td>
        </tr>
        <tr>
          <td>Register Date</td>
          <td>{{ detail.register_date || '-' }}</td>
        </tr>
        <tr>
          <td>User Count</td>
          <td>{{ detail.user_count || '-' }}</td>
        </tr>
        <tr>
          <td>Branch Count</td>
          <td>{{ detail.branch_count || '-' }}</td>
        </tr>
        <tr>
          <td>Product Count</td>
          <td>{{ detail.product_count || '-' }}</td>
        </tr>
        <tr>
          <td>Warehouse Count</td>
          <td>{{ detail.warehouse_count || '-' }}</td>
        </tr>
      </table>

      <table v-if="detail != null && detail.users.length > 0" class="table mt-2">
        <tr>
          <th colspan="6">
            User List:
          </th>
        </tr>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Phone</th>
          <th>Jabatan</th>
          <th>Last Login</th>
          <th>Branch</th>
        </tr>
        <tr v-for="user in detail.users" :key="user.uuid">
          <td>{{ user.name || '-' }}</td>
          <td>{{ user.email || '-' }}</td>
          <td>{{ user.phone || '-' }}</td>
          <td>{{ user.jabatan || '-' }}</td>
          <td>{{ user.last_login || '-' }}</td>
          <td>{{ user.branch_name || '-' }}</td>
        </tr>
      </table>
    </b-modal>
    <!-- Detail Modal -->

    <!-- Import Modal -->
    <b-modal id="import-modal" no-close-on-backdrop size="lg" title="Import" hide-footer>
      <div class="form">
        <div class="form-group">
          <label for="">File:</label><br>
          <input id="fileImport" ref="fileImport" type="file" @change="changeImportFile()">
        </div>

        <div v-if="isLoadingImport">
          <br>
          <b-spinner class="mb-2" variant="primary" /><br>
        </div>
        <button v-else class="btn btn-primary btn-sm" @click="importFile()">
          Import Data
        </button>
        <br>
        <hr>
        <small>Download import format <a href="/import-format/sku-import-format.xlsx">here</a></small>

      </div>
    </b-modal>
    <!-- Import Modal -->

    <!-- Add/Edit Modal -->
    <b-modal id="form-modal" size="xl" :title="editId != null ? 'Edit' : 'Add'" @shown="modalShown">

      <div class="form-group">
        <label for="">Name:</label>
        <input v-model="formPayload.name" type="text" class="form-control"
          :class="validations.hasOwnProperty('name') && validations.name.length > 0 ? 'is-invalid' : ''"
          placeholder="Toko Name...">
        <small v-if="validations.hasOwnProperty('name') && validations.name.length > 0" class="text-danger">
          {{ validations.name.join(', ') }}
        </small>
      </div>

      <div class="form-group">
        <label for="">Logo: (Optional)</label><br>
        <input id="file" ref="file" type="file" @change="changeImage()">
      </div>

      <div class="form-group">
        <label for="">Ecommerce Username:</label>
        <input v-model="formPayload.ecommerce_username" type="text" class="form-control"
          :class="validations.hasOwnProperty('ecommerce_username') && validations.ecommerce_username.length > 0 ? 'is-invalid' : ''"
          placeholder="Ecommerce Username...">
        <small v-if="validations.hasOwnProperty('ecommerce_username') && validations.ecommerce_username.length > 0"
          class="text-danger">
          {{ validations.ecommerce_username.join(', ') }}
        </small>
      </div>

      <div class="form-group">
        <label for="">Sales/Affiliate: (Optional)</label>
        <v-select id="sales_affiliate" v-model="formPayload.sales_affiliate" :options="formData.sales"
          :reduce="user => user.id" label="name" placeholder="Type to search sales..." @search="onSearchSales">
          <template slot="no-options">
            Type here to search sales...
          </template>
        </v-select>
      </div>

      <div class="row">
        <div class="col-md-6">

          <div class="form-group">
            <label for="">City:</label>
            <input v-model="formPayload.city" type="text" class="form-control"
              :class="validations.hasOwnProperty('city') && validations.city.length > 0 ? 'is-invalid' : ''"
              placeholder="City...">
            <small v-if="validations.hasOwnProperty('city') && validations.city.length > 0" class="text-danger">
              {{ validations.city.join(', ') }}
            </small>
          </div>

        </div>
        <div class="col-md-6">

          <div class="form-group">
            <label for="">Address:</label>
            <textarea v-model="formPayload.address" class="form-control"
              :class="validations.hasOwnProperty('address') && validations.address.length > 0 ? 'is-invalid' : ''"
              placeholder="Address..." />
            <small v-if="validations.hasOwnProperty('address') && validations.address.length > 0" class="text-danger">
              {{ validations.address.join(', ') }}
            </small>
          </div>

        </div>
      </div>

      <div class="mb-1">
        <strong>Users:</strong>
        <br>
        <br>
        <div v-for="(user, index) in formPayload.users" :key="index" class="row">
          <div class="col-3">
            <div class="form-group">
              <label for="">Name:</label>
              <input v-model="user.name" type="text" class="form-control" placeholder="User name..." required>
            </div>
          </div>

          <div class="col-2">
            <div class="form-group">
              <label for="">Jabatan:</label>
              <input v-model="user.jabatan" type="text" class="form-control" placeholder="Jabatan..."
                :readonly="user.jabatan == 'Owner' && index == 0" required>
            </div>

          </div>

          <div class="col-2">
            <div class="form-group">
              <label for="">Email:</label>
              <input v-model="user.email" type="email" class="form-control" placeholder="Email...">
            </div>

          </div>

          <div class="col-2">
            <div class="form-group">
              <label for="">Phone:</label>
              <input v-model="user.phone" type="text" class="form-control" placeholder="Phone...">
            </div>

          </div>

          <div class="col-2">
            <div class="form-group">
              <label for="">Password:</label>
              <input v-model="user.password" type="password" class="form-control" placeholder="Password...">
            </div>
          </div>

          <div class="col-1 pt-2">
            <b-button variant="danger" size="sm" @click="removeUser(index)">
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </div>

        <b-button class="mt-1" size="sm" variant="secondary" @click="addUser('')">
          Add User
        </b-button>
      </div>

      <!-- Map -->
      <div style="height: 350px;">
        <l-map ref="map" :zoom="zoom" :center="[
          position.lat || userLocation.lat || defaultLocation.lat,
          position.lng || userLocation.lng || defaultLocation.lng
        ]" @dblclick="onMapClick">
          <l-tile-layer :url="tileProvider.url" :attribution="tileProvider.attribution" />
          <l-marker v-if="position.lat && position.lng" visible draggable :lat-lng.sync="position"
            @dragstart="dragging = true" @dragend="dragging = false">
            <l-tooltip :content="tooltipContent" :options="{ permanent: true }" />
          </l-marker>
          <v-geosearch :options="geosearchOptions"></v-geosearch>
        </l-map>
      </div>

      <template #modal-footer="{}">
        <div v-if="isLoading">
          <br>
          <b-spinner class="mb-2" variant="primary" /><br>
        </div>
        <b-button v-if="editId == null" variant="success" :disabled="isLoading" @click="createItem()">
          Save Item
        </b-button>
        <b-button v-else variant="success" :disabled="isLoading" @click="updateItem()">
          Save Item
        </b-button>
      </template>
    </b-modal>
    <!-- Add/Edit Modal -->

    <!-- Add/Edit Modal Manual Subscription -->
    <b-modal id="form-modal-manual-subscription" size="lg" title="Add Manual Subscription" no-close-on-backdrop>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="">Toko</label>
            <v-select id="sales_affiliate" v-model="formPayload.merchant_id" :options="formData.merchants"
              :reduce="option => option.uuid" label="name" placeholder="Type to search merchant..."
              @search="onSearchMerchant">
              <template slot="no-options">
                Type here to search merchant...
              </template>
            </v-select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">

          <div class="form-group">
            <label for="">Pilih Paket</label>
            <v-select id="sales_affiliate" v-model="formPayload.subscription_renewal_id" :options="formData.packages"
              :reduce="option => option.id" label="name" placeholder="Type to search package..."
              @search="onSearchPackage">
              <template slot="no-options">
                Type here to search package...
              </template>
            </v-select>
          </div>

        </div>
      </div>

      <template #modal-footer="{}">
        <div v-if="isLoading">
          <br>
          <b-spinner class="mb-2" variant="primary" /><br>
        </div>
        <b-button variant="success" :disabled="isLoading" @click="createManualSubscription">
          Save Item
        </b-button>
      </template>
    </b-modal>
    <!-- Add/Edit Modal Manual Subscription -->

  </div>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner,
  BFormGroup, BTabs, BTab, BNavItem,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import Vue, { readonly } from 'vue'
import VuejsDialog from 'vuejs-dialog'
import VueBarcode from 'vue-barcode'
import FileSaver from 'file-saver'
import axios from 'axios'
import vSelect from 'vue-select'
import { StreamBarcodeReader } from 'vue-barcode-reader'

import {
  LMap, LTileLayer, LMarker, LPopup, LTooltip
} from 'vue2-leaflet'

import VGeosearch from 'vue2-leaflet-geosearch';
import { OpenStreetMapProvider } from 'leaflet-geosearch';

import 'vuejs-dialog/dist/vuejs-dialog.min.css'

Vue.use(VuejsDialog)

export default {
  title() {
    return 'Subscription'
  },
  components: {
    BFormGroup,
    BCard,
    BButton,
    BCardText,
    BCardCode,
    BDropdown,
    BDropdownItem,
    BTabs,
    BTab,
    BNavItem,
    BSpinner,
    VueBarcode,
    vSelect,
    StreamBarcodeReader,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    VGeosearch,
    LTooltip
  },
  props: {
    defaultLocation: {
      type: Object,
      default: () => ({
        lat: 8.9806,
        lng: 38.7578
      })
    }
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
    position: {
      deep: true,
      async handler(value) {
        this.address = await this.getAddress();
        this.formPayload.address_latitude = value.lat
        this.formPayload.address_longitude = value.lng
      }
    }
  },
  computed: {
    tooltipContent() {
      if (this.dragging) return "...";
      if (this.loading) return "Loading...";
      return `<strong>${this.address.replace(
        ",",
        "<br/>"
      )}</strong> <hr/><strong>lat:</strong> ${this.position.lat
        }<br/> <strong>lng:</strong> ${this.position.lng}`;
    }
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      // Map
      loading: false,
      geosearchOptions: { // Important part Here
        provider: new OpenStreetMapProvider(),
        autoClose: true,
        keepResult: true,
        showMarker: false,
      },
      tileProvider: {
        attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      },
      zoom: 18,
      userLocation: {},
      position: {},
      address: "",
      dragging: false,
      // End Map
      showBarcodeScanner: false,
      currentPage: 1,
      result: Object,
      isLoading: false,
      isLoadingImport: false,
      isLoadingExort: false,
      formPayload: Object,
      detail: null,
      editId: null,
      filter: {
        search: '',
      },
      importPayload: {
        file: null,
      },
      formData: {
        sales: [],
        merchants: [],
        packages: []
      },
      validations: {},
    }
  },
  created() {
    this.getData()
    this.getPackage()
  },
  mounted() {
    this.getUserPosition();
    this.$refs.map.mapObject.on("geosearch/showlocation", this.onSearch);
  },
  methods: {
    onSearchPackage(search, loading) {
      if (search.length) {
        loading(true)
        this.searchPackage(loading, search, this)
      }
    },
    searchPackage: _.debounce((loading, search, vm) => {
      vm.$http.get(`super-admin/subscriptions/package?search=${encodeURI(search)}`)
        .then(response => {
          vm.formData.packages = response.data.data.data.map(obj => ({
            id: obj.id,
            name: obj.renewal_name,
          }))
        })
        .catch(error => {
          console.error(error, 'package')
        })
      loading(false)
    }, 300),
    async getPackage() {
      try {
        const response = await this.$http.get(`super-admin/subscriptions/package`)
        this.formData.packages = response.data.data.map(obj => ({
          id: obj.id,
          name: obj.renewal_name,
        }))
      } catch (error) {
        console.log(error, 'package');
      }
    },
    async getAddress() {
      this.loading = true;
      let address = "Unresolved address";
      try {
        const { lat, lng } = this.position;
        const result = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`
        );
        if (result.status === 200) {
          const body = await result.json();
          address = body.display_name;
        }
      } catch (e) {
        console.error("Reverse Geocode Error->", e);
      }
      this.loading = false;
      return address;
    },
    async onMapClick(value) {
      // place the marker on the clicked spot
      this.position = value.latlng;
    },
    onSearch(value) {
      const loc = value.location;
      this.position = { lat: loc.y, lng: loc.x };
    },
    async getUserPosition() {
      if (navigator.geolocation) {
        // get GPS position
        navigator.geolocation.getCurrentPosition(pos => {
          // set the user location
          this.userLocation = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude
          };
        });
      }
    },
    modalShown() {
      setTimeout(() => {
        //mapObject is a property that is part of leaflet
        this.$refs.map.mapObject.invalidateSize();
      }, 100);
    },
    filterStatus(status) {
      this.filter.status = status
      this.getData()
    },
    addUser(jabatanDefault = '') {
      this.formPayload.users.push({
        name: '',
        email: '',
        phone: '',
        jabatan: jabatanDefault,
        password: '',
      })
    },
    removeUser(index) {
      this.formPayload.users.splice(index, 1)
    },
    changeImage() {
      // eslint-disable-next-line prefer-destructuring
      this.formPayload.logo = this.$refs.file.files[0]
    },
    onSearchSales(search, loading) {
      if (search.length) {
        loading(true)
        this.searchSales(loading, search, this)
      }
    },
    searchSales: _.debounce((loading, search, vm) => {
      vm.$http.get(`super-admin/sales?per_page=10&search=${encodeURI(search)}`)
        .then(response => {
          vm.formData.sales = response.data.data.data.map(obj => ({
            id: obj.id,
            name: `${obj.name} - ${obj.phone}`,
          }))
        })
        .catch(error => {
          console.error(error)
        })
      loading(false)
    }, 300),
    onSearchMerchant(search, loading) {
      if (search.length) {
        loading(true)
        this.searchMerchant(loading, search, this)
      }
    },
    searchMerchant: _.debounce((loading, search, vm) => {
      vm.$http.get(`super-admin/merchant?per_page=10&search=${encodeURI(search)}`)
        .then(response => {
          vm.formData.merchants = response.data.data.data.map(obj => ({
            uuid: obj.id,
            name: obj.name,
          }))
        })
        .catch(error => {
          console.error(error)
        })
      loading(false)
    }, 300),
    toggleBarcodeScanner() {
      this.showBarcodeScanner = !this.showBarcodeScanner
    },
    onDecode(result) {
      this.formPayload.barcode_number = result
    },
    onLoaded() {
      console.log('Barcode reader loaded')
    },
    generateBarcode() {
      this.formPayload.barcode_number = Math.floor(Math.random() * 90000000000) + 1
    },
    changeImportFile() {
      // eslint-disable-next-line prefer-destructuring
      this.importPayload.file = this.$refs.fileImport.files[0]
    },
    downloadImportFormat() {

    },
    importFile() {
      this.isLoadingImport = true

      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.importPayload) {
        if (this.importPayload.hasOwnProperty(key) && this.importPayload[key] != null) {
          form.append(key, this.importPayload[key])
        }
      }
      this.$http.post('/super-admin/merchant/import', form, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then(response => {
          successNotification(this, 'Success', 'Data successfully imported')
          this.isLoadingImport = false
          this.getData()
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
          this.isLoadingImport = false
        })
    },
    detailItem(item) {
      this.$http.get(`/super-admin/merchant/${item.id}`)
        .then(response => {
          this.detail = response.data.data
        })

      this.$bvModal.show('detail-modal')
    },
    exportData(type = 'excel') {
      this.isLoadingExort = true

      const context = this
      const axiosSource = axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel, msg: 'Loading...' }

      this.$http.get('super-admin/merchant/export', {
        responseType: type == 'view' ? '' : 'blob',
        params: this.filter,
        cancelToken: axiosSource.token,
      }).then(response => {
        var timestamp = Math.floor(Date.now() / 1000)
        if (type == 'view') {
          context.contentHtml = response.data.data.html
        } else {
          var timestamp = Math.floor(Date.now() / 1000)
          if (type == 'excel') {
            var fileName = `export-${timestamp}.xlsx`
            FileSaver.saveAs(response.data, fileName)
          }

          if (type == 'pdf') {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            var fileName = `export-${timestamp}.pdf`
            FileSaver.saveAs(blob, fileName)
          }
        }

        context.isLoading = false
        context.isLoadingExort = false
      }).catch(error => {
        context.isLoading = false
        context.isLoadingExort = false

        if (error.response.data.meta.messages.length > 0) {
          errorNotification(this, 'Oops!', error.response.data.meta.messages)
        }
      })
    },
    cleanUpForm() {
      this.editId = null
      this.formPayload = {
        name: '',
        logo: null,
        ecommerce_username: '',
        city: '',
        address: '',
        users: [],
      }
      this.position = {}
      this.address = ''
      this.addUser('Owner')
    },
    getData(page = 1) {
      this.isLoading = true
      this.currentPage = page
      const queryParams = this.filter
      queryParams.page = this.currentPage

      this.$http.get('/super-admin/merchant', {
        params: queryParams,
      })
        .then(response => {
          this.result = response.data.data
          this.formData.merchants = response.data.data.data.map(obj => ({
            uuid: obj.uuid,
            name: obj.name,
          }))
          this.isLoading = false
        })
    },
    createManualSubscription() {
      const form = this.preparePayload()

      this.$http.post('super-admin/subscriptions/package/manual', form)
        .then(response => {
          this.$bvModal.hide('form-modal-manual-subscription')
          this.getData(this.currentPage)
          successNotification(this, 'Success', 'Item successfully created')
          this.cleanUpForm()
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
        })
    },
    createItem() {
      const form = this.preparePayload()

      this.$http.post('/super-admin/merchant', form)
        .then(response => {
          this.$bvModal.hide('form-modal')
          this.getData(this.currentPage)
          successNotification(this, 'Success', 'Item successfully created')
          this.cleanUpForm()
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
        })
    },
    updateItem() {
      const form = this.preparePayload()
      form.append('_method', 'PATCH')

      this.$http.post(`/super-admin/merchant/${this.editId}`, form, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then(response => {
          this.$bvModal.hide('form-modal')
          this.getData(this.currentPage)
          successNotification(this, 'Success', 'Item successfully updated!')
          this.cleanUpForm()
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
        })
    },
    preparePayload() {
      const form = new FormData()

      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null && this.formPayload[key] != '') {
          form.append(key, this.formPayload[key])
        }
      }

      // Prepare formPayload.users
      if (this.formPayload.users) {
        this.formPayload.users.forEach((user, index) => {
          // eslint-disable-next-line no-restricted-syntax
          for (const key in user) {
            if (user.hasOwnProperty(key) && user[key] != null && user[key] != '') {
              form.append(`users[${index}][${key}]`, user[key])
            }
          }
        })
      }

      return form
    },
    editItem(item) {
      this.editId = item.id

      this.$http.get(`/super-admin/merchant/${item.id}`)
        .then(response => {
          if (response.data.data.referedBy !== null) {
            this.formData.sales = [{
              id: response.data.data.referedBy.id,
              name: `${response.data.data.referedBy.name} - ${response.data.data.referedBy.phone}`,
            }]
          }
          this.position = {
            lat: response.data.data.address_latitude,
            lng: response.data.data.address_longitude,
          }
          this.address = response.data.data.address
          this.formPayload = response.data.data
          this.formPayload.sales_affiliate = response.data.data.referedBy ? response.data.data.referedBy.id : null
        })

      this.$bvModal.show('form-modal')
    },
    deleteItem(id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to delete this item?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`super-admin/merchant/${id}`)
            .then(response => {
              this.getData(this.currentPage)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Item successfully deleted',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
